$action-menu-background-color: #141414
$action-menu-border-color: #333

.action-menu-container
  position: relative

.action-menu-popup
  position: absolute
  right: 0
  top: 100%
  z-index: 100
  background-color: $action-menu-background-color
  margin: 2px 0
  border: 2px solid $action-menu-border-color
  border-radius: 2px
  color: #fff
  font-size: 14px

.action-menu-item
  cursor: pointer
  padding: 9px 10px
  white-space: nowrap

  &:hover
    background-color: #333

  > i
    margin-right: 4px
