.app-settings-content
  +page-padding

  .app-settings-form
    margin-top: 24px
    display: flex
    flex-direction: column

    input[type='email']
      margin: 8px 0 16px
    input[type='text']
      margin: 8px 0 16px
    input[type='radio']
      margin: 0 8px 0 24px
    input[type='checkbox']
      margin: 0 8px 0 0

    button
      max-width: 150px

    div
      display: flex
      flex-direction: row
      align-items: center



    span
      font-size: 14px

    svg
      fill: #9A9EA1
      width: 20px
      height: 20px
      margin: 0 5px

    .status
      color: #DAECF8
      font-size: 13px

.form-label-tip
  display: inline-block !important
  max-width: 550px





