.screen-create-modal

  form
    display: flex
    flex-direction: column

  label
    display: block

  input
    margin: 8px 0 24px


  button
    display: block

  &.hidden
    display: none
