@import "src/styles/visual-editor"

.action-property-panel
  width: 100%
  background-color: #141414
  border: 1px solid $visual-editor-panel-border-color
  border-radius: 10px
  padding: 10px 10px

.action-property-panel-header
  display: flex
  justify-content: space-between
  align-items: center

.action-property-panel-header-title
  div:first-child
    font-size: 13px
    color: #8C8C8C
  div:last-child
    font-size: 22px


