@import "src/styles/property-panel-widgets"

.schema-field-content
  display: flex
  justify-content: space-between
  align-items: center

  // use monospace font for expression
  input[type="text"]
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace
    font-size: 12px

  // this is the label and widget
  .custom-field-template-content
    flex-grow: 1

  &-expression
    width: 30px
    flex-shrink: 0
    margin-bottom: $property-panel-widget-gap
    text-align: center

    &-button
      opacity: 0
      transition: opacity 0.3s ease-in-out
      cursor: pointer
      color: #b7b7b7
      padding-left: 6px

    // in Expression state
    &-button-active
      opacity: 1
      color: white

    // not in Expression state but has expression. We need a way to distinguish this from the default state
    &-button-has-expression
      opacity: 1
      color: #03ECED

.schema-field-content:hover .schema-field-content-expression-button
  opacity: 1
