$visual-editor-background-color: #111
$visual-editor-border-color: #333333
$visual-editor-header-border-color: #5a5959
$visual-editor-input-border-color: #444

// colors for Interactive UI elements (text alignment, ...)

// for example Alignment boxes border use secondary for border, primary for selected
$visual-editor-ui-interactive-color: #646464
$visual-editor-ui-interactive-focus-color: #a2a2a2
$visual-editor-ui-interactive-selected-color: #d4d4d4

// for general panel
$visual-editor-panel-background-color: #191B1E
$visual-editor-panel-selected-background-color: #373B3F
$visual-editor-panel-border-color: #2F3032
$visual-editor-panel-text-color: #F0F2F5
$visual-editor-panel-hint-text-color: #A1A4A9

// tree
$tree-node-border-color: #4f4f4f
$tree-node-selected-bg-color: #373B3F

// base property panel
.property-panel
  background-color: #141414
  border: 1px solid $visual-editor-panel-border-color
  border-radius: 10px
  padding: 10px 10px

// tooltip when inside the property panel
.property-panel-tooltip
  .ant-tooltip-inner, .ant-tooltip-arrow::before
    background-color: #2f2f2f !important

// emphasize the value (e.g. default value, or the value from the description)
.property-panel-tooltip-emphasized-token
  font-size: 15px
  color: #2BAADF
  font-weight: 600


// display a tooltip on hover
.tooltip-container
  .tooltip
    position: absolute
    margin-top: 10px
    max-width: 150px
    z-index: 10000
    visibility: hidden
    opacity: 0
    transition: opacity 0.3s
    background-color: #333
    color: white
    border-radius: 8px
    padding: 7px 10px
    font-size: 16px

  &:hover .tooltip
    visibility: visible
    opacity: 1
    font-size: 16px

.action-icon
  cursor: pointer
  transition: color 0.3s
  font-size: 20px

  &:hover
    color: #e1e1e1


// for modal
$visual-editor-modal-background-color: #000
.modal-container
  font-family: "Montserrat", sans-serif

  .ant-modal-content
    border: 1px solid #444
    box-shadow: 0 0 10px #444
    background-color: $visual-editor-modal-background-color
    color: white

    .ant-modal-header
      background-color: $visual-editor-modal-background-color
      color: white

      .ant-modal-title
        color: white
        font-size: 22px
        font-weight: normal
        padding-left: 20px

    > button, button:hover
      color: white

// Special Right Modal snap to the right with top offset and left-over max height
$right-modal-top: 110px
$right-modal-padding: 20px
$right-modal-header-height: 33px
$right-modal-header-margin-bottom: 8px
.right-modal-container
  margin-right: 0
  // start from the top offset and end exactly at the screen bottom
  // we do this by setting max-height below
  top: $right-modal-top

  .ant-modal-content
    padding: $right-modal-padding

    .ant-modal-header
      height: $right-modal-header-height
      margin-bottom: $right-modal-header-margin-bottom

    .widget-builder-property-panel
      max-height: calc(100vh - $right-modal-top - $right-modal-padding * 2 - $right-modal-header-height - $right-modal-header-margin-bottom)

// don't put scrollbar on the Modal. We'll handle it ourselves
.ant-modal-wrap
  overflow-y: hidden !important


// modal for discard changes
.discard-changes-confirmation
  .ant-btn-default
    &:hover
      color: #1A232E !important
      border-color: #1A232E !important

  .ant-btn-primary
    margin-top: 10px
    background-color: #c55353

    &:hover
      background-color: #d31b1b !important


// overrides for antd components
.ant-popover, .ant-list-items
  font-family: Montserrat, sans-serif

.ant-modal
  width: fit-content !important
