@import "src/styles/_common.sass"

.language-selector-anchor
  height: 34px
  width: 34px
  background: #000
  border-radius: 4px
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer

.language-selector-anchor-default
  font-size: 20px
  color: #999

.language-selector-anchor-language
  font-size: 22px

.language-selector-dropdown-no-language
  background-color: #000
  border: #ccc
  color: #ccc
  max-width: 260px
  padding: 15px

  img
    width: 100%
    object-fit: contain

  .no-language-action
    text-align: center
    font-size: 16px

    a:hover
      color: $primary
