.account-content
  +page-padding

  button
    margin: 20px 0
  
.plan-section
  span
    color: $grey-10
  .plan-button
    padding: 10px 20px
    background-color: $primary
    border-radius: 3px
    font-weight: bold
    cursor: pointer
  .cancel-button
    padding: 10px 20px
    background-color: black
    border-radius: 3px
    border: 1px solid $error
    font-weight: bold
    color: $error
    cursor: pointer
