.beta-waitlist-ct
  width: 500px
  height: 80vh
  display: flex
  flex-direction: column
  margin: 50px auto
  align-content: center
  justify-content: center

  img
    width: 200px
    margin: 20px auto

  p
    margin: 8px 0 4px
    text-align: center

  a
    margin: 0 4px

  button
    margin: 10px auto
