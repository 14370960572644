.widget-selection-panel
  position: absolute
  width: 300px
  top: 62px
  bottom: 48px
  z-index: 1500
  background: #141414
  border: solid #333 1px
  box-shadow: 0 0 10px #333
  display: flex
  flex-direction: column

.widget-selection-panel-header

  > input[type='search']
    border: none
    outline: none
    width: 100%
    background: $grey-1
    color: white
    font-size: 15px
    padding: 14px 10px 4px 10px

  // disabled state for the tabs
  > .widget-selection-disabled-tab-container
      > .widget-selection-tab
        color: #666
        border-bottom: none
        cursor: not-allowed
        pointer-events: none

  > .widget-selection-tab-container
    padding: 10px 10px 8px 10px
    border-bottom: 1px #333 solid

    > .widget-selection-active-tab
      @extend .widget-selection-tab
      border-bottom: 2px white solid
      color: white
      cursor: default

    > .widget-selection-inactive-tab
      @extend .widget-selection-tab
      border-bottom: none
      color: #999
      cursor: pointer

.widget-selection-panel-body
  height: 100%
  padding: 10px
  display: flex
  flex-direction: column
  overflow-y: auto

  > div
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    cursor: pointer
    padding: 0 10px
    font-weight: 500
    font-size: 14px
    color: white
    line-height: 40px

    > span
      padding-left: 8px

    > a
      display: none

    &:hover, &:focus
      background: #1A232E

      > a
        display: inline-block

.widget-selection-tab
  font-size: 14px
  background-color: #141414
  padding: 6px 0
  margin-right: 10px
