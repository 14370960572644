.tree
  --spacing: 1.5rem
  --radius: 10px
  //max-width: 40vw
  color: $text-grey
  font-weight: 400
  padding-left: 20px
  width: 100%
  font-family: "Roboto Mono", sans-serif
  font-size: 0.87rem
  .add-icon
    float: right
    cursor: pointer
    &:hover
      background-color: $grey-2
  .no-content
    font-size: 0.78rem
    color: $grey-6
    padding-left: 5px

  .custom-widget-ct
    margin: 6px 0

    .heading-view
      border-bottom: 1px solid $grey-2
      padding-bottom: 0.75rem
      margin-left: 5px
    &::before
      display: block
      content: ''
      position: absolute
      top: 10px
      left: 10px
      width: 0.6rem
      height: 0.6rem
      border-radius: 50%
      background-color: #333333
  li
    display: block
    position: relative
    padding-left: calc(1.6 * var(--spacing) - var(--radius) - 2px)

  ul
    padding-left: 0
    animation: fadeIn 500ms
    li
      border: 0
      width: 100%
      margin-top: 5px
      &:last-child
        border-color: transparent
  ul:not(:first-child)
    li
      &::before
        content: ''
        display: block
        position: absolute
        top: calc(var(--spacing) / -2)
        left: -2px
        width: calc(var(--spacing) + 2px)
        height: calc(var(--spacing) + 6px)
        border: solid #222222
        border-width: 0 0 2px 2px
        border-bottom-left-radius: 14px
      &.no-graph-line::before
        content: none


  summary
    display: block
    cursor: pointer
    padding: 0 0
    margin-top: 6px
    border-top-left-radius: 5px
    border-bottom-left-radius: 5px
    .expand-icon
      margin-right: 4px
      transition-duration: 400ms
      fill: $grey-9

    &:hover
      .expand-icon
        fill: $primary-hover
    &:focus
      outline: none
    &:focus-visible
      outline: 1px dotted #000

  details[open]
    & > summary
      color: #e9e9e9
      & > .heading-view > .expand-icon
        transform: rotate(180deg)
        transition-duration: 400ms


.summary-parent
  margin-left: (calc(0.04* var(--spacing) - var(--radius) - 10px))

.content-view
  display: flex
  align-items: center
  padding: 5px 0
  border-top-left-radius: 5px
  border-bottom-left-radius: 5px
  &:hover
    background-color: rgba(0, 0, 0, 0.24)
    color: $white-darker

.selected-widget
  background-color: #1a232e
  border-right: 2px solid $primary
  border-radius: 5px 0 0px 5px

.heading-view
  display: flex
  //margin: 10px 0 0 0
  //padding-left: 5px
  align-items: center
  padding-bottom: 6px
  padding-top: 5px
  border-top-left-radius: 5px
  border-bottom-left-radius: 5px
  &:hover
    background-color: rgba(0, 0, 0, 0.24)
  .flex-row
    display: flex
    width: 100%

  button
    all: unset
    //margin: 2px
    cursor: pointer


.widget-btn
  all: unset
  display: flex
  cursor: pointer
  width: 100%
  .flex-row
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center

.search-section
  padding: 20px 20px 5px 20px

.trees-section
  max-height: calc(100vh - 15rem)
  overflow-y: auto
  overflow-x: hidden

  &::-webkit-scrollbar
    display: none


.add-btn
  all: unset
  cursor: pointer
  display: flex
  align-items: center
  padding: 0 15px
  border: 1px solid white
  border-radius: 18px
  height: 1.87rem
  font-family: 'Montserrat', sans-serif
  color: white
  margin-top: 8px
  font-size: .8rem
  .add-icon
    width: 1rem
    margin-right: 4px
    fill: white
  &:hover
    background-color: $grey-3

.icon-ct
  width: 1.5rem
  height: 1.5rem
  border-radius: 4px
  display: flex
  align-items: center
  justify-content: center
  background-color: #000
  img
    width: 1rem
    height: 1rem
    object-fit: cover

.icon-selected
  background-color: transparent

.heading
  font-family: 'Montserrat', sans-serif
  color: white
  margin: 18px 10px 10px 5px
  font-size: .8rem
  color: $grey-9

.border-bottom
  padding-bottom: 5px
  border-bottom: 1px solid $grey-2

.screen-content-ct
  font-family: "Roboto Mono", sans-serif
  font-weight: 500
  padding: 10px 20px
  .flex-row
    display: flex
    margin-top: 3px
    align-items: center
    justify-content: flex-start
    p
      margin-bottom: 0
      font-size: 0.85rem


  .screen-property
    color: #BC8D77
    font-weight: 500
    margin-left: 5px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  p
    color: $text-grey
    font-weight: 400
    font-size: 0.87rem

.heading-large
  font-size: 1.25rem
  margin: 5px

.heading-text
  margin-left: 0.5rem
  line-height: normal
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  max-width: 200px
  .text-id
    margin-left: 8px
    color: $grey-6

.searched-heading-text
  color: #20DC9D
  font-weight: 600

.list-active
  border-right: 2px solid red

.tree summary::marker,
.tree summary::-webkit-details-marker
  display: none

.tree
  ul:not(:first-child)
    border-left: 2px solid #222222
    &:last-child
      border-left-color: transparent
    li
      border: 0px
      padding-left: 28px
  li
    padding-left: 12px
  summary
    position: relative
    z-index: 9
  & > li
    & > details
      ul
        position: relative
        &:nth-child(2):before
          content: ""
          border-left: 2px solid #222222
          display: block
          position: absolute
          top: -24px
          left: -2px
          height: 18px
      & > ul
        margin-left: 0px

.tree-context-menu
  position: absolute
  top: 0
  display: flex
  flex-direction: column
  z-index: 1000
  background: #141414
  border-left: solid #a0a0a0 1px
  overflow-y: auto
  height: 100%

  > input[type='search']
    border: 1px solid $grey-4
    background: $grey-1

  > h1
    margin-bottom: 0
    color: $grey-9

  > *
    display: flex
    flex-direction: row
    justify-content: space-between
    cursor: pointer
    padding: 0 20px
    font-weight: 500
    font-size: 14px
    color: white
    line-height: 40px
    > a
      display: none

    &:hover
      background: #1A232E

      > a
        display: inline-block


::-webkit-scrollbar
  width: 3px
  height: 3px

@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1
