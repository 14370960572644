.screen-content
  +page-padding
  width: 100%

  .input-box__error
    visibility: hidden

  .error
    input,
    input[type=text]:focus
      border-color: $error
    
    .input-box__error
      color: $error
      font-size: 14px
      visibility: visible

  .page-header
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: top
    margin-bottom: 24px

    .input-box
      display: flex
      flex-direction: column
      gap: 10px

      label
        color: #fff
        font-size: 22px
        font-weight: 500

    .page-actions
      display: flex

      .collaborators-share-action
        height: 44px
        display: flex
        align-items: center
        a
          display: flex
          align-items: center
          text-decoration: none
          margin: 0 16px 0 0

          .avatar-placeholder
            height: 40px
            width: 40px

            .avatar-letter
              font-size: 16px
              font-weight: bold
              color: #666

          .collaborator-avatar
            height: 40px
            width: 40px

          svg
            height: 40px
            width: 40px
            padding: 5px
            color: #666

        .collaborators-share-button
          color: #03ECED
          font-weight: 500
          margin: 0 32px 2px 8px
          cursor: pointer

      .actions-menu-button
        margin-left: 16px

        svg
          fill: $primary

    +md
      flex-direction: column


    .button__link
      margin: 0 16px

    .appIdCopyConfirmation
      color: $grey-9
      font-size: 12px
      font-weight: bold

  .app-description
    position: relative
    top: -24px

  .screen-cards-ct
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr))
    gap: 16px

    a
      color: white
      text-decoration: none

  .screen-cards-st
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr))
    gap: 16px

    a
      color: white
      text-decoration: none

  .create-screen-ct
    margin: 32px 0

  .segment
    margin-top: 10px
    display: inline-flex
    align-items: flex-start
    gap: 8px
    background-color: #222
    border-radius: 8px
    padding: 4px
    flex-wrap: wrap
    cursor: pointer

    .label
      padding: 10px
      background-color: transparent
      border-radius: 8px

      &.selected
        background-color: #000



