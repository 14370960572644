.apps-content
  +page-padding
  width: 100%
  height: 100vh
  display: flex
  flex-direction: column
  position: relative

  .page-header
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    margin-bottom: 24px

    button
      margin-left: 24px


  .empty-state-ct
    margin-bottom: 40px

  .app-categories
    display: flex
    margin-bottom: 20px
    span
      color: $grey-9
      cursor: pointer
      align-self: center
      padding: 5px 10px
      font-size: 14px
    .selected
      color: white
      background-color: $grey-3
      border-radius: 5px


  .page-footer
    padding-top: 24px
    display: flex

  .app-cards-ct
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
    gap: 24px
    padding-bottom: 40px

    a
      color: white
      text-decoration: none

  .help-content
    color: $grey-9
    margin: 32px 0

    a
      color: $grey-9
