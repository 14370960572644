$picker-background-color: #101010
$picker-hover-background-color: #3d3c3c

.screen-type-picker
  font-family: "Montserrat", sans-serif

  &-anchor
    display: flex
    justify-content: center
    align-items: center
    border-style: dashed
    border-radius: 4px
    cursor: pointer
    border-width: 1px
    border-color: #999
    color: white
    font-size: 14px
    margin: 5px 0 5px 16px
    padding: 40px 0
    &:hover
      background-color: $picker-hover-background-color

  &-content
    width: 250px

  &-content-item
    cursor: pointer
    padding: 1rem !important
    border-radius: 4px
    border-color: $picker-hover-background-color !important
    &:hover, &:focus
      background-color: $picker-hover-background-color

  .ant-list-item-meta-title a,
  .ant-list-item-meta-description
    color: white !important

  &-content-title
    font-weight: normal
    color: white
