.editor-code
  height: 100%
  display: flex
  flex: 1

  .editor
    // overflow-y: hidden
    // overflow-x: auto
    flex: 1
  .widget-tree
    position: relative
    flex: 0 1 280px
    padding-bottom: 50px
    height: 100%
    background-color: #141414
    border-right: 1px solid $separator-grey
    transition: width 0.3s

    > div
      overflow-y: auto

  .monaco-base
    flex: 1 1 50vw

  .readOnlyNote
    font-size: 14px
    margin: 0 0 12px 28px

  > .widget-property-panel
    width: initial
    left: 280px

.title-bar
  background-color: #141414
  height: 50px
  margin-bottom: 10px
  padding-top: 25px
  display: flex
  flex-direction: row
  .title
    color: #999999
    font-size: 14px
    margin-left: 30px
    flex-basis: 50%
  .current-title
    flex-basis: 50%

.resizer-x
  position: relative
  display: flex
  justify-content: center
  align-items: center
  background: $grey-2
  padding: 2px
  z-index: 5
  cursor: col-resize
  &:hover
    &:before
      background: $white-darker
  &:active
    &:before
      background: $primary-hover
  &:before
    content: ""
    width: 2px
    border-radius: 5px
    height: 4%
    margin: 0
    background: $grey-5

.editor-footer-ct
  position: absolute
  z-index: 10
  width: 100%
  height: 44px
  bottom: 0px
  padding-left: 64px
  background: black
  border-left: 1px solid $grey-1

  button
    margin: 6px 12px 0px 24px
    border-radius: 4px
    padding: 8px 16px
    background: $grey-3
    font-weight: 600
    letter-spacing: .8px

    &:hover
      background: $grey-2


  span
    font-size: 11px
    font-weight: 600
    color: $grey-6

    img
      width: 16px
      margin-bottom: -4px

.widget-toggle-btn
  margin: 6px 4px 8px 4px
  cursor: pointer
  border-radius: 4px
  display: block
  background-color: $grey-1
  color: white
  .flex-row
    display: flex
    align-items: center
    color: $text-grey
    font-weight: 400
    font-size: 0.87rem
  &:hover
    background-color: $charcoal


.lineDecoration
  background-color: rgba(185, 185, 185, 0.27)
  padding: 2px

.monaco-editor,
.monaco-editor-background,
.margin
  background-color: #101010 !important
  --vscode-editor-background: #101010 !important

.monaco-editor
  position: absolute !important

.monaco-base
  height: 100%
