// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px

// Small tablets (portrait view)
$screen-md-min: 768px

// Tablets and small desktops
$screen-lg-min: 992px

// Large tablets and desktop
$screen-xl-min: 1200px


// Small devices
@mixin sm
   @media (max-width: #{$screen-sm-min})
       @content


// Medium devices
@mixin md
   @media (max-width: #{$screen-md-min})
       @content

// Large devices
@mixin lg
   @media (max-width: #{$screen-lg-min})
       @content


// Extra large devices
@mixin xl
   @media (max-width: #{$screen-xl-min})
       @content

// Custom devices
@mixin rwd($screen)
   @media (max-width: $screen+'px')
       @content

