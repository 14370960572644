@import "src/styles/property-panel-widgets"

// the label, widget
.custom-field-template-content
  display: flex
  align-items: center

  input
    // we sometimes want to align the input with other fields
    margin-bottom: 0

  > .field-label
    width: $property-panel-field-label-width
    flex-shrink: 0
    font-size: 13px

    &.required::after
      content: '*'
      color: red
      margin-left: 4px

    &-right
      justify-content: right

  > .field-widget
    flex-grow: 1
    // gap for each field. Apply here so longer field label doesn't shift content around
    margin-bottom: $property-panel-widget-gap

// each item root when FieldTemplate is rendering key/value pairs
.field-template-additional-properties-item
  display: flex
  align-items: stretch

  &-actions
    display: flex
    align-items: center
    justify-content: end
    width: $property-panel-field-label-width
    padding-right: 6px
    margin-bottom: $property-panel-widget-gap

    &-separator
      margin-right: 3px
      border-right: 2px solid #676767
      height: 100%

  &-remove
    padding: 0 1px
    cursor: pointer

  &-content
    flex-grow: 1
