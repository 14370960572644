.screen-card
  height: 340px
  border: 1px solid $grey-2
  border-radius: 8px
  display: flex
  flex-direction: column

  &.assets
    height: 250px

  &:hover
    border: 1px solid $grey-4

  pre
    &::after
      overflow: hidden
      content: attr(data-text)
      -webkit-line-clamp: 13
      display: -webkit-box
      -webkit-box-orient: vertical
      
  &__code
      font-family: monospace
      font-size: 14px
      line-height: 20px
      border-top-right-radius: 8px
      border-top-left-radius: 8px
      height: 260px
      overflow: hidden
      padding: 12px
      opacity: 0.4

      pre
        &::after
          overflow: hidden
          content: attr(data-text)
          -webkit-line-clamp: 13
          display: -webkit-box
          -webkit-box-orient: vertical
  
  &__asset-image
    border-top-right-radius: 8px
    border-top-left-radius: 8px
    height: 170px
    overflow: hidden
    background-repeat: no-repeat
    background-position: center
    background-size: contain

  &__image
    border-top-right-radius: 8px
    border-top-left-radius: 8px
    height: 260px
    background-size: cover
    overflow: hidden

  &__content
    position: relative
    padding: 24px
    background: black
    height: 80px
    border-top: 1px solid $grey-2
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px

    h4
      text-overflow: ellipsis
      overflow: hidden
    span
      min-height: 12px

    &-info
      font-size: 11px
      font-weight: bold
      color: $grey-6

      i
        color: $grey-4
        margin: 0 8px

  .actions-menu-button
    opacity: 0
    position: absolute
    right: 12px
    bottom: 24px

.screen-card:hover
  .actions-menu-button
    opacity: 1

