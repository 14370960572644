.app-publish-modal
  padding: 20px
  background: $grey-1
  height: 100%
  overflow: hidden

  .icon_container
    display: flex
    align-items: center
    gap: 10px
    margin: 30px 0

  form
    display: flex
    flex-direction: row
    align-items: flex-end
    gap: 20px
    margin-bottom: 10px

  label
    display: block

  input,
  select
    margin-top: 8px
    width: 250px

  &.hidden
    display: none

  select
    color: white
    height: 40px
    padding: 10px
    border: 1px solid #444
    background: #111
    border-radius: 2px

  .publish
    margin-top: 20px

  .button__link_app
    display: flex
    align-items: center
    text-decoration: none
    font-weight: 600

  .publish-report-container
    height: 80%
    overflow-y: auto
    overflow-x: auto
    background: #000000
    padding: 20px


    table
      width: 100%
    td, th
      border-bottom: 1px solid $grey-3
      text-align: left
      padding: 8px
      font-weight: 500
      font-size: 12px
      color: $grey-9
      vertical-align: middle

      &.row-name
        color: $primary
        font-size: 14px
        font-weight: 500

      .inline
        display: flex
        flex-direction: row
        align-items: center

    th
      font-weight: 600

    .checkbox
      width: 60px

    td:nth-child(2)
      min-width: 300px
      max-width: 300px

    td:nth-child(2)
      width: 60px

    td:nth-child(5),
    td:nth-child(6)
      div
        width: 160px

  .loader
    width: 48px
    height: 48px
    border-radius: 50%
    position: relative
    animation: rotate 1s linear infinite

    &::before
      content: ""
      box-sizing: border-box
      position: absolute
      inset: 0px
      border-radius: 50%
      border: 5px solid #FFF

  .app-badge
    color: #FFF
    font-size: 11px
    font-weight: 700
    border-radius: 4px
    display: flex
    padding: 5px
    min-width: 75px
    justify-content: center
    align-items: center

    &.root
      margin-left: 6px
      background-color: #2B2B2B
    &.add
      background-color: #004650
    &.update
      background-color: #013B80
    &.archive
      background-color: #500000
      

@keyframes rotate
  100%
    transform: rotate(360deg)

