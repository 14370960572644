.translation-add-language-popup

  .ant-popover-arrow
    display: none
  .ant-popover-inner
    background-color: #000
    color: white

  .ant-menu
    background-color: #000
    color: white
    font-size: 16px

  .ant-menu-item:hover
    color: white !important
    background-color: #333 !important
    border-radius: 2px



.translation-add-language-menu
  max-height: 500px
  overflow-y: auto
  background-color: #000 !important
  border: 1px solid #333

  .ant-dropdown-menu-item
    color: white !important

  .ant-dropdown-menu-item-active
    background-color: #333 !important

.translation-add-language-menu-item
  display: flex
  gap: 8px
  align-items: center
