.script-screen
  padding: 35px 50px 5px 85px

  .action-menus
    position: fixed
    right: 20px
    top: 64px
    display: flex
    flex-direction: row
    gap: 20px
    align-items: center

    .save-script
      .checkin-button
        background-color: $primary
        color: black
        font-weight: bold
        border-radius: 3px
        padding: 8px 15px
        cursor: pointer
        margin: 0px
  
    .unsaved-changes
      color: $grey-9
      padding-right: 20px
      font-size: 12px
      display: flex

      .unsaved-changes-icon
        filter: invert(66%) sepia(23%) saturate(3186%) hue-rotate(355deg) brightness(97%) contrast(98%)
        margin-right: 5px
      p
        margin: 0
        padding: 0

    .history-section
      display: flex
      margin-left: auto
      .dropdown
        color: #fff
      a
        font-size: 12px
        line-height: 16px
        text-decoration: none

      .title
        font-size: 14px
      .checkin-button
        background-color: #03ECED
        color: black
        font-weight: bold
        border-radius: 3px 0 0 3px
        padding: 0 15px
        cursor: pointer
        margin: 0px
    .close-diff-text
      align-self: center
      padding-right: 20px
      color: #3dc9b0
      cursor: pointer
      &:hover
        text-decoration: underline

.script-screen-card
  height: 75px
  background: #000
  border: 1px solid $grey-2
  display: flex
  flex-direction: column
  border-radius: 8px
  padding: 16px 16px 16px 25px 

  &__content
    position: relative
    display: flex
    flex-direction: row
    align-items: center
    background: black
    border-radius: 8px
    gap: 10px

    h6
      color: #999
      font-size: 14px
      font-style: normal
      font-weight: 500
      line-height: 20px

  .space-between  
    justify-content: space-between
