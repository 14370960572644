.resources-content
  +page-padding
  width: 100vw

  h4
    font-weight: 600
    margin: 4px 0 16px
  a
    text-decoration: none
    font-weight: 600
    font-size: 16px
    
  li
    list-style: circle
    margin: 8px 0 8px 14px
  
  .icon
    padding: 16px
    background: $grey-1
    width: 64px
    height: 64px
    border-radius: 64px
    margin-bottom: 16px
    svg
      fill: green
      width: 32px
      height: 32px

  .resource-cards
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
    gap: 16px
    margin: 16px 0 40px
  
    >div
      background: $grey-2
      padding: 16px 16px 24px
      border-radius: 8px
      display: flex
      flex-direction: column
      justify-content: space-between
      gap: 12px
