@import "src/styles/visual-editor"
@import "src/styles/property-panel-widgets"

.object-field-template
  position: relative
  margin-bottom: $property-panel-widget-gap

.object-field-template::before
  content: ""
  position: absolute
  left: -$widget-property-panel-horizontal-padding
  right: -$widget-property-panel-horizontal-padding
  border-top: 1px solid $visual-editor-header-border-color

.object-field-template::after
  content: ""
  position: absolute
  left: -$widget-property-panel-horizontal-padding
  right: -$widget-property-panel-horizontal-padding
  border-bottom: 1px solid $visual-editor-header-border-color

// at the root we don't show the header or border
.object-field-template-root
  &::before, &::after
    border: none
  > .object-field-template-header
    display: none

.object-field-template-header
  display: flex
  justify-content: space-between
  align-items: center
  padding: 16px 0
  font-size: 14px

.object-field-template-header-actionable
  cursor: pointer

// makes it stand out when collapsed
.object-field-template-header-collapsed
  font-weight: bold
  &::before
    content: ""
    position: absolute
    top: 0
    bottom: 0
    left: -$widget-property-panel-horizontal-padding
    right: -$widget-property-panel-horizontal-padding
    background-color: #1e1e1e
    z-index: -1

.object-field-template-header-title
  display: flex
  align-items: center

  &-help-icon
    margin-left: 5px
    font-size: 20px
    color: #b0b0b0


.object-field-template-root-header
