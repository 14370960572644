@import "src/styles/visual-editor"
$tree-node-bottom-gap: 10px

.action-tree
  min-width: 400px
  font-family: "Montserrat", sans-serif

  .ant-tree
    background-color: #000
    // non-selected tree node
    &-node-content-wrapper
      padding: 0

    // selected tree node
    &-node-selected
      background-color: transparent !important
      .action-item-title-container
        background: $tree-node-selected-bg-color !important

.action-item-title-container
  padding: 4px 10px
  min-width: 200px
  margin-bottom: $tree-node-bottom-gap
  border: 1px solid $tree-node-border-color
  border-radius: 6px

.action-item-subtitle
  font-size: 13px
  color: #949494

.action-item-title-group
  display: flex
  justify-content: space-between
  align-items: center

.action-item-title
  font-size: 14px

.action-item-title-icon
  font-size: 16px
  padding: 0 4px
  border-radius: 4px
  color: white
  opacity: 0
  transition: opacity 0.3s

  &:hover
    background-color: #565656

.action-item-title-container:hover .action-item-title-icon
  opacity: 1


// TODO: get the lines to appear right
//.action-tree
//  .ant-tree
//    &-switcher
//      pointer-events: none
//    &-switcher > .anticon
//      display: none
//
//    // change the connector lines
//    &-switcher-leaf-line::before
//      top: -$tree-node-bottom-gap
//      border-inline-end: 1px solid $tree-node-border-color
//    &-switcher-leaf-line::after
//      border-bottom: 1px solid $tree-node-border-color
//    &-indent-unit::before
//      border-inline-end: 1px solid $tree-node-border-color
