@import "src/styles/common"
@import "src/styles/visual-editor"

$picker-background-color: #101010
$picker-hover-background-color: #3d3c3c

.item-picker
  &-body
    width: 300px

    input[type='search']
      margin-top: 5px
      border: none
      outline: none
      width: 100%
      background: $grey-1
      color: white
      font-size: 15px
      padding: 14px 15px
      margin-bottom: 0 !important

    &-anchor
      display: flex
      justify-content: center
      align-items: center
      border-style: dashed
      border-radius: 4px
      cursor: pointer
      border-width: 1px
      border-color: #999
      color: white
      font-size: 14px
      padding: 20px 40px
      &:hover
        background-color: $visual-editor-panel-selected-background-color

    &-title
      font-weight: normal
      color: #9FA2A7
      padding: 16px

    // scrollable content
    &-content
      height: 600px
      overflow-y: scroll

// antd overrides
.item-picker
  .ant-popover-content
    border: 2px solid $visual-editor-panel-border-color
    border-radius: 12px
    background-clip: padding-box
  .ant-popover-title
    color: #9FA2A7
    margin-bottom: 0
  .ant-popover-inner
    padding: 0
    background-color: $visual-editor-panel-background-color
  .ant-popover-inner-content
    padding: 0
  .ant-list-item-meta-title a,
  .ant-list-item-meta-description
    color: $visual-editor-panel-text-color !important

  .ant-tabs .ant-tabs-ink-bar
    background-color: $visual-editor-panel-text-color
  .ant-tabs-nav::before
    border-bottom: 1px solid $visual-editor-panel-border-color
  .ant-tabs-nav-list
    margin-left: 14px
  .ant-tabs-tab
    color: $visual-editor-panel-hint-text-color
    &:hover
      color: $visual-editor-panel-text-color !important
  .ant-tabs-tab-btn:active
      color: $visual-editor-panel-text-color !important
  .ant-tabs-tab-active > .ant-tabs-tab-btn
    color: $visual-editor-panel-text-color !important
  .ant-empty-description
    color: $visual-editor-panel-hint-text-color


.item-picker-menu-item
  cursor: pointer
  padding: .8rem !important
  border-radius: 4px
  border-color: $picker-hover-background-color !important
  &:hover, &:focus
    background-color: $visual-editor-panel-selected-background-color

  &-content
    display: flex
    align-items: center
    gap: 5px

.item-picker-trigger
  cursor: pointer

.action-picker-menu
  max-height: 80vh
  overflow-y: auto
  background-color: $visual-editor-panel-background-color
  font-size: 16px
  border-radius: 5px

.ant-popover .ant-popover-arrow
  &::before
    background-color: #575757 !important


.ant-list-item
  border-bottom: none !important


