@import "src/styles/visual-editor"
@import "src/styles/property-panel-widgets"

.text-alignment-field
  display: flex
  flex-direction: row
  height: $property-panel-widget-height
  align-items: center

.text-alignment-cell
  cursor: pointer
  border-radius: 2px
  color: $visual-editor-ui-interactive-color
  font-size: 22px
  padding: 2px
  &:hover
    color: $visual-editor-ui-interactive-focus-color

.text-alignment-field .selected
  color: $visual-editor-ui-interactive-selected-color

.text-alignment-after-primary-actions
  margin-right: 16px
