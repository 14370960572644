@import "src/styles/common"

.action-builder-field
  font-family: "Montserrat", sans-serif

  &-placeholder
    display: inline-block
    cursor: pointer
    font-size: 14px
    padding: 7px 8px
    border-radius: 2px
    background-color: transparent
    border: 1px solid #a1a1a1
    color: #a1a1a1
    transition: color 0.3s, border-color 0.3s

    &:hover
      border-color: #dcdcdc
      color: #dcdcdc

  &-preview
    cursor: pointer
    border: 1px solid $grey-4
    padding: 11px 10px
    border-radius: 4px

    // container for action name and short description
    &-title
      display: flex
      align-items: center

    // the action name
    &-name
      font-size: 14px

    &-short
      margin-left: 8px
      font-style: italic
      font-size: 13px
      color: #e0e0e0

    &-long
      margin-top: 6px
      font-size: 13px
      color: #a1a1a1
