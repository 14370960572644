.app-card
  height: 300px
  border: 1px solid $grey-2
  border-radius: 8px
  display: flex
  flex-direction: column

  &:hover
    border: 1px solid $grey-4

  &__image
    border-top-right-radius: 8px
    border-top-left-radius: 8px
    height: 200px
    background-size: contain
    background-position: center bottom
    background-repeat: no-repeat
    background-color: black
    overflow: hidden

  &__content
    position: relative
    padding: 16px
    background: black
    height: 100px
    border-top: 1px solid $grey-2
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px

    h4
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      font-size: 16px

    span
      min-height: 12px

    &-info
      font-size: 12px
      font-weight: bold
      color: $grey-6
      margin: 12px 0

      i
        color: $grey-4
        margin: 0 8px

      p
        font-size: 14px
        font-weight: 500
        color: $grey-10
        line-height: unset
        overflow: hidden
        text-overflow: ellipsis
        margin-bottom: 4px

      &-ct
        display: flex
        align-items: center
        gap: 5px

  .actions-menu-button
    opacity: 0
    position: absolute
    right: 12px
    bottom: 24px

  .app-description
    white-space: nowrap

  .demo-description
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: 2
    line-height: 1.3

.app-card:hover
  .actions-menu-button
    opacity: 1

.collaborator-avatar
  width: 30px
  height: 30px
  border-radius: 50%
  margin-left: -6px
  border: 2px solid black
  cursor: pointer

.avatar-placeholder
  width: 30px
  height: 30px
  border-radius: 50%
  margin-left: -6px
  border: 2px solid black
  display: flex
  align-items: center
  justify-content: center
  background-color: $grey-2

  .avatar-letter
    font-size: 12px
    font-weight: bold
    color: $grey-6
    margin-bottom: 0px

.homepage-collaborator
  display: flex
  align-items: center

.more-collaborators
  display: flex
  align-items: center
  justify-content: center
  background: black
  border: 2px solid $grey-2
