@import "../visual-editor"

.tree-panel
  font-family: "Montserrat", sans-serif

.tree-block
  margin-bottom: 18px

.tree-block-header
  font-size: 11px
  color: #999999
  padding-bottom: 4px
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 4px

.tree-block-header-with-separator
  @extend .tree-block-header
  border-bottom: 1px solid $visual-editor-border-color

.ant-tree
  font-family: "Montserrat", sans-serif
  background: #101010
  color: #fff

  // all tree nodes have default border radius, we need to have it the same
  // as our selected borderRadius so there is no styling jumping issue
  .ant-tree-node-content-wrapper
    border-radius: 5px 0 0 5px !important
    display: flex
    flex-direction: row
    justify-content: start


.tree-block-body
  .ant-tree-treenode
    padding: 0 // we account for the padding in content-wrapper

    .ant-tree-switcher
      display: flex
      align-items: center
      width: 16px

    // careful here, if modify this then also need to
    // modify the .tree-node-add 's negative margin and padding
    .ant-tree-node-content-wrapper
      padding: 6px 0 6px 2px

    .ant-tree-node-selected
      background: #393939
      border-right: 2px solid #03eced

.ant-tree-title
  color: #DDDDDD
  flex: 1

// subtitle appear to the right of the title
.ant-tree-subtitle
  color: #999999
  font-style: italic
  font-size: 13px
  margin-left: 10px

.ant-tree-node-icon-container
  height: 1.5rem
  display: flex
  align-content: center
  align-items: center
  color: #0BA182

.ant-tree-node-icon
  fill: #0BA182

// "Add body" placeholder block for screen's body or standalone widget's body
.set-screen-or-widget-body
  @extend .set-body-placeholder
  margin: 5px 0 5px 16px
  padding: 40px 0

// "Add body" placeholder block for widgets within a screen
.set-widget-body
  @extend .set-body-placeholder
  padding: 7px 0
  margin: 0 0 0 20px

.set-body-placeholder
  display: flex
  justify-content: center
  align-items: center
  border-style: dashed
  border-radius: 4px
  cursor: pointer
  border-width: 1px
  border-color: #999
  color: white
  font-size: 14px

  &:hover
    background-color: #3d3c3c

// the title block for the tree
.tree-node-title
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center

// Add child widget
.tree-node-add
  display: flex
  flex-direction: row
  align-items: center

  // do negative margin so we take over the entire space
  // Make sure this is in sync with .ant-tree-node-content-wrapper padding
  margin: -6px 0 -6px -2px
  padding: 6px 0 6px 2px

  > .ant-tree-node-icon-container
    width: 24px
    height: 24px

  > span
    flex: 1
    font-style: italic
    color: #2BAADF


.screen-action-menu-anchor
  display: flex
  position: relative
  justify-content: right

  // anchor
  button
    cursor: pointer
    padding: 2px 4px
    border-radius: 2px
    background-color: transparent
    border: 1px solid #a1a1a1
    color: #a1a1a1
    transition: color 0.3s, border-color 0.3s

    &:hover
      border-color: #dcdcdc
      color: #dcdcdc

    span
      padding: 10px 0 10px 4px
