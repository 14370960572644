@import _media-queries

.empty-content
    display: flex
    flex-direction: column
    align-items: center
    .empty-assets
        max-width: 100%
        width: 30rem
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        gap: 32px
        img
            max-width: 100%
            width: 27.5rem
        .empty-assets__text
            text-align: center

.assets-actions
    display: flex
    justify-content: center
    &--normal
        width: 20rem
    &--dense
        width: 19rem
    &__buttons
        width: 100%
        display: flex
        justify-content: center
    button
        width: 9rem
