@import "../../styles/common"
@import "src/styles/property-panel-widgets"

.widget-property-panel
  overflow-x: visible
  width: 100%
  z-index: 20
  background-color: #141414

  .widget-property-panel-content
    padding: 5px $widget-property-panel-horizontal-padding
    .tooltip
      margin-left: 5px
      cursor: pointer
      padding-top: 2px
      .description
        max-width: 400px
        padding: 10px 20px
    .error-message
      font-size: 12px
      font-weight: bold
      color: red

  .widget-property-panel-header
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 1em

  .widget-property-panel-header-title
    display: flex
    align-items: center

    > span
      font-size: 22px
      font-weight: 400
      margin-right: 10px

    //> input[type="text"]
    //  font-size: 20px
    //  font-weight: 500
    //  padding: 0
    //  margin-right: 0
    //  margin-bottom: 0
    //  &:disabled
    //    border: none
    > svg
      cursor: pointer
      width: 16px
      height: 16px

  select
    display: block
    color: white
    width: 300px
    height: 40px
    padding: 0 16px
    border: 1px solid $grey-4
    background: $grey-1
    border-radius: 2px
    margin-bottom: 4px
    width: 100%

    &:focus,
    &:focus-visible,
    &:active
      border: 1px solid $grey-9
      outline: none

  input[type="text"],input[type="integer"], textarea
    //margin-bottom: 1em
    width: 100%

  textarea
    background-color: $grey-1
    color: white
    border-radius: 2px
    border: 1px solid $grey-4
    min-height: 100px
    &:focus,
    &:focus-visible,
    &:active
      border: 1px solid $grey-9
      outline: none

  textarea
    background-color: $grey-1
    color: white
    border-radius: 2px
    border: 1px solid $grey-4
    &:focus,
    &:focus-visible,
    &:active
      border: 1px solid $grey-9
      outline: none

  legend
    font-size: 16px

  label
    display: flex
    align-items: center
    font-size: 14px
    font-weight: 400
    color: white
    margin-bottom: 5px

  small, .mb-3
    font-size: 12px
    font-weight: 500
    color: $grey-9

  h5
    font-size: 14px
    font-weight: 500
    margin-bottom: 0
  hr
    margin-top: 0
    margin-bottom: 16px

  .form-check
    display: flex
    flex-direction: row-reverse
    align-items: center
    justify-content: space-between

  .field-description
    font-size: 12px
    margin-bottom: 0

  fieldset > .field:first-of-type
    margin-top: 1em

  .field
    margin-bottom: 1em

  .widget-property-panel-submit-btn
    margin-right: 1em

  .row.d-flex
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    > div:first-child
      flex-grow: 1
      margin-right: 1em

  .category-selector
    display: flex
    flex-direction: row
    justify-content: space-around
    padding: 4px 8px
    border-radius: 4px
    background-color: #000000
    font-weight: 500
    margin-bottom: 1em

    .category
      all: unset
      padding: 4px 16px
      cursor: pointer
      flex-grow: 1
      text-align: center
    .active-category
      border-radius: 4px
      background-color: #222222

.widget-set-id-placeholder
  color: #999
  font-size: 13px

.widget-set-id-text
  color: #999
  font-size: 13px
  padding-left: 16px
  font-style: italic

