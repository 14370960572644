.download_store_container
  background: $charcoal
  border-radius: 4px
  max-width: 45rem
  display: flex
  justify-content: space-between
  align-items: center
  padding: 1.5rem
  margin-bottom: 3rem
  flex-wrap: wrap

.download_store_item
  flex: 2

.download_store_item_qr
  text-align: end
  flex: 1
  img
    max-width: 10rem
