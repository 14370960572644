@import "src/styles/visual-editor"

.widget-builder
  display: flex
  flex-direction: row

.widget-builder-tree-panel
  min-width: 350px
  margin-right: 20px

// wrapper around WidgetPropertyPanel
.widget-builder-property-panel
  min-width: 400px
  background-color: $visual-editor-background-color
  border-left: 1px solid $visual-editor-border-color
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  overflow-y: auto
  scrollbar-gutter: stable
  height: 100%

// override the root's Tree style
.widget-builder
  .ant-tree
    background-color: #000
  .ant-tree-treenode
    > .ant-tree-node-content-wrapper
      //border: 1px solid $tree-node-border-color
      //border-radius: 4px
    > .ant-tree-node-selected
      //background: $tree-node-selected-bg-color
      //margin-right: 20px

