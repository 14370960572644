.template-screen-card
  height: 440px
  margin-top: 25px
  border: 2px solid $grey-2
  border-radius: 8px
  display: flex
  flex-direction: column
  position: relative
  align-items: center
  cursor: pointer

  .templateBtn
    display: none
    position: absolute
    bottom: 20px

  &:hover
    border: 2px solid $primary

    .templateBtn
      display: block

  &__image
    border-top-right-radius: 8px
    border-top-left-radius: 8px
    height: 380px
    background-size: contain
    overflow: hidden
    width: 100%
    background-repeat: no-repeat
    background-position: center

  &__content
    position: relative
    padding: 16px
    text-align: center
    border-top: 1px solid $grey-2
    border-top-left-radius: 8px
    border-top-right-radius: 8px

    span
      min-height: 12px

    &-info
      font-size: 11px
      font-weight: bold
      color: $grey-6

      i
        color: $grey-4
        margin: 0 8px

  .actions-menu-button
    opacity: 0
    position: absolute
    right: 12px
    bottom: 24px

.template-screen-card:hover
  .actions-menu-button
    opacity: 1
