@import _resets
@import _media-queries
@import _common

// components
@import components/app-publish-modal
@import components/modal
@import components/dropdown
@import components/nav
@import components/app-card
@import components/app-create-modal
@import components/app-preview-modal
@import components/screen-card
@import components/template-screen
@import components/root-screen-badge
@import components/demo-app-badge
@import components/screen-create-modal
@import components/editor-code
@import components/editor-preview
@import components/editor-preview-size-selection
@import components/editor-preview-zoom-selection
@import components/actions-menu
@import components/theme-editor
@import components/youtube-embed
@import components/collaborator-card
@import components/env-card
@import components/user-questionnaire
@import components/download-store
@import "components/widget-tree"
@import "components/tree-panel"
@import "components/navigator-panel"
@import components/script-screen
@import "components/widget-selection-panel"
@import "field-widgets"
@import "visual-editor"
@import "property-panel-widgets"

// pages
@import signin
@import beta-waitlist
@import apps
@import app-screens
@import app-settings
@import editor
@import account
@import resources
@import invite

body
  font-family: 'Montserrat', sans-serif
  color: white
  accent-color: $primary
  background: $grey-1

.page-ct
  display: flex
  flex-direction: row

.breadcrumb
  display: flex
  flex-direction: row
  font-weight: 600
  align-items: center
  margin-bottom: 8px

  a, span, button
    margin-right: 8px
    color: $grey-9
    font-size: 12px
    line-height: 16px
    text-decoration: none

  .demo-app-badge
    color: #208b5e
    margin-left: 6px

  .public-app-badge
    color: $primary
    margin-left: 6px

.preview-ct
  position: fixed
  z-index: 1000

  iframe
    width: 100vw
    height: 100vh
