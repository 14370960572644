@import "src/styles/property-panel-widgets"

.array-field-add-button
  cursor: pointer
  padding: 6px 8px
  border-radius: 4px
  background-color: transparent
  border: 1px solid #a1a1a1
  color: #a1a1a1
  transition: color 0.3s, border-color 0.3s

  &:hover
    border-color: #dcdcdc
    color: #dcdcdc

  span
    padding: 10px 0 10px 4px

// each row in the array field
.array-field-draggable-item
  display: flex
  align-items: center

  &-actions
    display: flex
    align-items: center
    justify-content: end
    width: $property-panel-field-label-width
    padding-right: 6px
    margin-bottom: $property-panel-widget-gap

  &-content
    flex-grow: 1



  &-draggable
    padding: 0 3px
    cursor: grab

  &-remove
    padding: 0 1px
    cursor: pointer

