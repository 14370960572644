@import "property-panel-widgets"

.unsupported-widget
  display: flex
  align-items: center
  font-size: 15px
  height: $property-panel-widget-height

  > span
    font-style: italic
    color: #2BAADF
    cursor: pointer
