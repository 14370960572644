
$primary: #03ECED
$primary-hover: #13D8D9
$grey-0: #0A0A0A
$grey-1: #111
$grey-2: #202020
$grey-3: #333
$grey-4: #444
$grey-5: #555
$grey-6: #666
$grey-9: #999
$grey-10: #AAA
$white: #ffffff
$white-darker: #dedede
$charcoal: #1B1F22
$text-grey: #a0a0a0
$separator-grey: #1a1a1a
$error: #ff0000

=page-padding
  padding: 32px 40px 40px 104px


h1
  font-size: 32px
  line-height: 40px
  margin-bottom: 16px

h2
  font-size: 24px
  line-height: 32px
  margin-bottom: 16px

h3
  font-size: 20px
  font-weight: 500
  line-height: 28px
  margin-bottom: 16px

h4
  font-size: 18px
  font-weight: 500
  margin-bottom: 8px

h5
  line-height: 30px
  font-weight: bold
  margin-bottom: 8px

hr
  border: none
  border-bottom: 1px solid $grey-4
  margin: 24px 0

p
  margin-bottom: 8px
  line-height: 24px

p.small
  font-size: 14px
  color: $grey-9

.center
  width: 100%
  height: 90vh
  display: flex
  justify-content: center
  align-items: center

a
  color: $primary

label
  font-size: 12px
  font-weight: 600
  color: $grey-9

// default fonts for form inputs
input, textarea, select, button
  font-family: Montserrat, sans-serif
  font-size: 13px

input[type='text'],
input[type='email'],
input[type='number'],
input[type='integer']
  color: white
  max-width: 300px
  width: 100%
  height: 40px
  padding: 16px
  border: 1px solid $grey-4
  background: $grey-1
  border-radius: 2px
  margin-right: 20px
  transition: all 0.2s linear

  &:focus,
  &:focus-visible,
  &:active
    border: 1px solid $grey-9
    outline: none


input[type="checkbox"]
  appearance: checkbox
  //-webkit-appearance: none
  //appearance: none
  background: black
  border: 2px solid $grey-9
  width: 16px
  height: 16px
  cursor: pointer

input[type="radio"]
  -webkit-appearance: none
  appearance: none
  border-radius: 50%
  background: black
  border: 2px solid $grey-9
  width: 16px
  height: 16px
  cursor: pointer

  &:checked
    background: $primary

.button
  &__primary
    background: $primary
    border-radius: 2px
    color: black
    padding: 0 32px
    height: 44px
    font-weight: bold
    cursor: pointer

  &__primary:disabled
    background: $grey-4
    color: $grey-6

  &__primary:hover
    background: $primary-hover

  &__secondary
    background: none
    border: 2px solid $primary
    border-radius: 2px
    color: $primary
    padding: 0 32px
    height: 44px
    font-weight: 600
    cursor: pointer

  &__secondary:disabled
    border: 2px solid $grey-4
    color: $grey-6

  &__secondary:hover
    background: $grey-1

  &__link
    background: none
    border: none
    color: $primary
    cursor: pointer
    font-weight: 700
    padding: 8px 16px

  &__icon
    background: none
    border: none
    color: $grey-6
    padding: 0px
    height: 24px
    cursor: pointer
    img
      color: $grey-6


// SCROLLBAR STYLES
::-webkit-scrollbar
  width: 6px
  height: 6px
::-webkit-scrollbar-button
  width: 0px
  height: 0px
::-webkit-scrollbar-thumb
  background: #444444
  border-radius: 50px
  &:hover
    background: #444444
  &:active
    background: #333333
::-webkit-scrollbar-track
  background: #222222

  &:active
    background: #333333
::-webkit-scrollbar-corner
  background: transparent
