@import "src/styles/property-panel-widgets"
@import "src/styles/visual-editor"

.widget-picker-trigger
  display: inline-block
  cursor: pointer

.widget-picker-trigger-label
  display: flex
  flex-direction: row
  align-items: center
  gap: 6px
  padding: 4px 10px 4px 0
  height: $property-panel-widget-height
  color: #dadada
  &:hover
    color: white

.widget-picker-trigger-label-placeholder
  @extend .widget-picker-trigger-label
  font-size: 14px


