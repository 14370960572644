.dropdown-wrapper
  position: relative

  .dropdown-button
    font-family: 'Montserrat', sans-serif
    font-weight: 600
    background: none
    border: none
    display: flex
    align-items: flex-start
    cursor: pointer

    &:after
      content: ""
      border-bottom: 2px solid $grey-6
      border-right: 2px solid $grey-6
      height: 8px
      width: 8px
      margin-left: 8px
      margin-top: 2px
      transform: rotate(45deg)

  .no-dropdown-icon
    background: none

  ul
    background-color: black
    max-height: 50vh
    min-width: 100px
    overflow-y: auto
    border: 2px solid $grey-2
    width: max-content
    margin: 4px 0 0
    padding: 8px 0
    position: absolute
    left: -10px
    z-index: 100

    li
      list-style: none
      margin: 0
    a,
    a:link
      display: block
      padding: 6px 16px
      margin-right: 0
      color: $white
      &:hover
        background-color: $grey-2
