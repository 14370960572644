.env-card, .env-card-editable
  display: flex
  flex-direction: row
  gap: 40px
  border-bottom: 1px solid $grey-2
  padding: 8px 0
  margin-bottom: 8px
  font-size: 14px

  p:first-child, div:first-child
    width: 250px

  p:nth-child(2), div:nth-child(2)
    max-width: 400px
    text-wrap: wrap
    word-wrap: break-word

  div:last-child,
  p:last-child
    margin-left: auto

  a
    text-decoration: none

  input[type='text']
    width: 250px

.env-card-editable
  border-bottom: none

  input[type="text"]
    width: 250px
    margin-top: 10px

  button
    margin-top: 23px

.status
  color: #DAECF8
  font-size: 13px
