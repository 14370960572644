@import "src/styles/visual-editor"
@import "src/styles/property-panel-widgets"

.card-field-template-header
  display: flex
  justify-content: space-between
  font-size: 14px
  padding: 14px 0
  margin-right: 20px
  margin-bottom: $property-panel-widget-gap
  cursor: pointer
  .card-field-template-header-title
    > span
      padding-right: 5px

.card-field-template-header-collapsed
  font-weight: bold
  border-bottom: 1px solid $visual-editor-border-color

.composite-field-body
  border-left: 2px solid $visual-editor-border-color
  padding-left: 20px
  margin-bottom: $property-panel-widget-gap
