@import "src/styles/visual-editor"

.alignment-grid
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-row-gap: 7px
  grid-column-gap: 10px
  width: 120px
  height: 70px
  padding: 2px 0
  border: 1px solid $visual-editor-ui-interactive-color

.alignment-cell
  display: flex
  align-items: center
  justify-content: center
  //border: 1px solid #ccc
  cursor: pointer

  &:hover .alignment-cell-dot
    background-color: $visual-editor-ui-interactive-color

.alignment-cell.selected > .alignment-cell-dot
  background-color: $visual-editor-ui-interactive-selected-color
  border-color: $visual-editor-ui-interactive-selected-color

.alignment-cell-dot
  width: 8px
  height: 8px
  border: 1px solid $visual-editor-ui-interactive-color
