@import "src/styles/visual-editor"
@import "src/styles/property-panel-widgets"

.icon-select-widget
  display: flex
  flex-direction: row
  height: $property-panel-widget-height
  align-items: center

.icon-select-widget-cell
  cursor: pointer
  border-radius: 2px
  color: $visual-editor-ui-interactive-color
  font-size: 22px
  padding: 2px 6px 2px 2px
  &:hover
    color: $visual-editor-ui-interactive-focus-color

.icon-select-widget .selected
  color: $visual-editor-ui-interactive-selected-color
