.editor-preview-ct
  background: url('../../assets/preview_bg.jpg')
  flex: 0 0 auto
  width: 388px
  height: calc(100% - 44px)
  padding: 16px
  display: block
  align-items: center
  justify-content: center

  iframe
    display: block
    height: 100%
    width: 100%

  .options-dropdown-wrapper
    margin: -1px
  .options-dropdown-list
    margin: 10px 0 0 -100px
    a
      margin-right: 8px
      font-size: 12px
      line-height: 16px
      text-decoration: none
