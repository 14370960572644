nav
  position: fixed
  z-index: 1
  top: 0
  left: 0
  width: 4rem
  height: 100%
  background: black
  padding: 1rem
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  transition: width 0.3s
  + div
    margin-left: 0
    transition: margin-left 0.3s
  ul
    list-style: none
    li
      a
        display: flex
        align-items: center
        padding: 0.5rem
        position: relative
        text-decoration: none
        border-radius: 4px
        font-size: 14px
        font-weight: 500
        color: $grey-9
        svg
          fill: $grey-9
        .nav_menu_label
          display: none
          margin-left: 0.8rem

      .nav_tooltip
        visibility: hidden
        background-color: $grey-4
        color: white
        left: 2.8rem
        text-align: center
        border-radius: 4px
        font-weight: 600
        font-size: 1rem
        padding: .6rem
        position: absolute
        z-index: 2

      &:hover
        .nav_tooltip
          visibility: visible

    .nav_menu_header
      margin-bottom: 2rem
      a
        color: $white
        font-weight: 500

    .nav_menu_item
      margin: 0.8rem 0

      .active
        background: $grey-2
        color: $white
        svg
          fill: $white

    .nav_divider
      border-top: 1px solid $grey-3
      margin: 0.8rem 0


  .nav_footer
    .subscription_plan
      background-color: $grey-1
      padding: 10px
      border-radius: 5px
      h6
        cursor: pointer
        font-size: 12px
        margin-top: 10px
        color: $primary
        &:hover
          text-decoration: underline
    .icon_section
      display: flex
      gap: 10px
      .plan_icon
        height: 30px
        width: 30px
      span
        align-self: center
    .nav_collapsable
      display: flex
      justify-content: center
      svg
        cursor: pointer


//Override upon nav no collapse
.nav_open
  width: 200px
  overflow-y: auto
  align-items: start
  + div
      margin-left: 150px
  ul
    width: 100%
    .nav_menu_label
      display: initial
    .nav_collapsable
      justify-content: end
    .nav_tooltip
      visibility: hidden !important

.profile-avatar
  width: 24px
  height: 24px
  border-radius: 50%
  object-fit: cover
  margin: 8px
  filter: drop-shadow(1px 1px 2px #0f0f0f)
