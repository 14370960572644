.options-list
  display: flex
  flex-direction: column

.option-btn
  color: white
  width: 100%
  height: 40px
  padding: 16px
  border: 2px solid $grey-4
  cursor: pointer
  line-height: 0
  text-align: left
  margin-top: 10px
  background: $grey-1
  border-radius: 2px

.question-title
  color: $primary-hover

.option-selected
  border: 2px solid $primary-hover

.questionnaire-ct
  min-height: 500px
  max-height: 70vh
  display: block
  width: 680px
  position: fixed
  border-radius: 10px
  background: $grey-0

.glass-view-ct
  width: 100vw
  height: 100vh
  background: rgba(0, 0, 0, 0.65)
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1)
  backdrop-filter: blur(20px)
  -webkit-backdrop-filter: blur(20px)
  border: 1px solid rgba(0, 0, 0, 0.21)
  position: absolute
  display: flex
  align-items: center
  justify-content: center
  z-index: 99999
  top: 0
  left: 0

.reminder-ct
  display: flex
  justify-content: center
  position: fixed
  bottom: 10px
  color: $white
  font-size: 1rem
  span>button
    all: unset
    font-weight: 500
    cursor: pointer
    color: $primary
    margin-left: 5px
    &:hover
      text-decoration: underline

  img
    width: 15px
    height: 15px
    margin-right: 4px

.loader-icon
  position: absolute
  z-index: 10
  top: 10px
  right: 10px

// CF-FORM STYLES
.conversational-form
  &:before
    display: none !important
  box-shadow: 0 4px 10px rgba(40, 40, 40, 0.32)
  background: $grey-1 !important
  border-radius: 10px
  border: 1px solid $grey-2
  cf-input
    background: $grey-1 !important

  cf-chat-response.robot
    text>p
      background: $grey-0 !important

  cf-chat-response.user
    text>p
      background: #e7e8e7 !important

