@import "../../styles/common"

.toggle-container
    display: flex
    align-items: center
    cursor: pointer
    border-top: 1px solid #333333
    padding: 10px 0
    &:hover
        background-color: rgba(0, 0, 0, 0.24)
        .show-icon
            fill: $primary-hover
        .hide-icon
            fill: $primary-hover

.show-icon
    transition-duration: 400ms
    fill: #999999
    transform: rotate(180deg)
.hide-icon
    transition-duration: 400ms
    fill: #999999
