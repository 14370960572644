.preview-zoom-selection
  background: $grey-1
  position: fixed
  bottom: 44px
  right: 32px
  padding: 8px 16px 0
  border: 3px solid $grey-3
  border-bottom: none

  .button__link
    display: block
    margin: 4px 0
    padding: 6px 8px
    border-radius: 4px
    text-align: left
    color: white
    width: 48px

    &:hover
      color: $primary
      span
        color: $primary

    span
      display: inline-block
      text-align: left
      font-family: monospace
      width: 94px
      color: $grey-9

  .selected
    background: $grey-3