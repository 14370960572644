.error-list-template
  border-left: 2px solid #ff4949
  margin-bottom: 20px
  font-size: 15px
  font-style: italic
  max-width: 360px

  &-item
    margin-left: 10px
    margin-bottom: 10px
    color: #ff4949
    word-wrap: break-word
