@import '../../styles/common'

.editor-header
  display: flex
  flex-direction: row
  justify-content: space-between
  padding: 12px 16px
  background-color: #000
  border-bottom: 1px solid #333

  .breadcrumbs
    display: flex
    flex-direction: row
    align-items: center
    margin-bottom: 0

    .nav-icon
      width: 1rem
      height: auto
      fill: $grey-9
      &:hover
        fill: $primary-hover
    a
      margin: 0 8px
      font-weight: 600
      &:hover
        color: $white-darker

  a, span, button
    margin-right: 8px
    color: $grey-9
    font-size: 12px
    line-height: 16px
    text-decoration: none

  .source-toggle
    color: $primary
    background: none
    font-weight: 600
    font-size: 14px
    cursor: pointer

  .options-dropdown-list
    right: 20px
    top: 112px
    left: unset !important

  .preview-action
    position: absolute
    z-index: 1
    right: 8px
    top: 80px

  .button__preview
    border: 1px solid grey
    padding: 5px 10px
    border-radius: 3px
    display: flex
    align-items: center
    gap: 5px

.history-section
  display: flex
  margin-left: auto
  .checkin-button
    background-color: #03ECED
    color: black
    font-weight: bold
    border-radius: 3px 0 0 3px
    padding: 0 15px
    cursor: pointer
    margin: 0px
  .close-diff-text
    align-self: center
    padding-right: 20px
    color: #3dc9b0
    cursor: pointer
    &:hover
      text-decoration: underline

  .unsaved-changes
    color: $grey-9
    padding-right: 20px
    font-size: 12px
    display: flex
    .unsaved-changes-icon
      filter: invert(66%) sepia(23%) saturate(3186%) hue-rotate(355deg) brightness(97%) contrast(98%)
      margin-right: 5px
    p
      margin: 0
      padding: 0

  .dropdown
    border: 1px solid grey
    padding: 5px 10px
    border-radius: 3px
  .clone-button
    padding: 7px 10px
    border-radius: 3px
    margin: 0 10px
  .clone-text
    align-self: center
  .save-dropdown-wrapper
    margin: -1px
  .save-dropdown
    border: 1px solid black
    background-color: #03ECED
    border-radius: 0 3px 3px 0
    padding: 5px 0
    height: 100%
    margin: 0px
    padding: 5px 10px 5px 3px
  .save-dropdown-list
    margin: 10px 0 0 -80px
  .set-home-screen
    margin-left: -110px
  .save-dropdown-list > li:nth-last-child(3)::after
    content: ""
    display: block
    margin: 4px 0
    border-top: 1px solid gray

.icon-dropdown
  font-size: 20px !important
  margin-top: 2px !important
