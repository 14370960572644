@import '../../../styles/common'

.artifact-inputs-modal
    span
        font-size: 14px
    .inputs-section
        margin: 20px 0
        max-height: 200px
        overflow-y: auto
        padding-right: 10px
        .single-input-section
            display: flex
            gap: 10px
            margin: 10px 0
            .remove
                cursor: pointer
    .add-button
        color: $primary
        textDecoration: underline
        cursor: pointer
        font-size: 14px
    .action-buttons
        margin-top: 20px
        display: flex
        gap: 10px
        float: right