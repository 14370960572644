.app-create-modal

  form
    display: flex
    flex-direction: column

  label
    display: block

  input
    margin: 8px 0 24px

  &.hidden
    display: none

  select
    color: white
    width: 300px
    height: 40px
    padding: 10px
    border: 1px solid #444
    background: #111
    border-radius: 2px
    margin-right: 20px
    margin-bottom: 20px