.root-screen-badge
  position: absolute
  bottom: 18px
  left: 24px
  height: 24px
  padding: 4px 8px
  font-size: 12px
  line-height: 16px
  font-weight: 700
  background: $grey-3
  color: $grey-9
  border-radius: 15px
