.property-panel-widget-error-wrapper
  position: relative

.property-panel-widget-error
  border: 1px solid red
  > input
    border-color: transparent !important

.property-panel-widget-error-tooltip
  position: absolute
  background-color: red
  color: white
  padding: 5px
  border-radius: 4px
  top: 100%
  left: 0
  transform: translateY(10px)
