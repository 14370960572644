@import "src/styles/_common.sass"

.language-card
  height: 200px
  background: #000
  border: 1px solid #222
  border-radius: 8px
  display: flex
  flex-direction: column

.language-card-flag
  flex-grow: 1
  font-size: 80px
  display: flex
  justify-content: center
  align-items: center

.language-card-content
  display: flex
  align-items: center
  gap: 10px
  border-top: 1px solid #222
  padding: 16px 16px 16px 25px

  .language-card-text
    flex-grow: 1

.language-card-default
  color: #999
  font-size: 14px

