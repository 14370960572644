.szh-menu
	min-width: 80px

.actions-menu
	background: $grey-2
	color: white

	.szh-menu__item
		padding: 8px 16px

	.szh-menu__item--hover
		background: $grey-1
	
	.szh-menu__divider
		background-color: $grey-4

	&__delete-item
		color: red


.actions-menu-button
	height: 40px
	border-radius: 2px
	background: $grey-2
	padding: 0
	cursor: pointer

	svg
		position: relative
		margin: 8px

	&:hover
		svg
			fill: $primary