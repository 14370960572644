.navigator-panel-control
  font-size: 22px
  color: #999
  &:hover
    color: #fff

.navigator-panel
  font-family: "Montserrat", sans-serif
  background-color: #101010
  margin-left: 10px
  width: 320px
  display: flex
  flex-direction: column

.navigator-panel-content
  flex-grow: 1
  padding-right: 5px
  overflow-y: auto
  scrollbar-gutter: stable

.action-tab-container
  display: flex
  flex-direction: row
  justify-content: left
  align-items: flex-end
  //border-bottom: 2px solid $grey-2

.action-tab-closed
  margin: 0 0 0 10px
  background-color: #101010
  width: 50px
  display: flex
  justify-content: center

.action-tab-section
  width: 20rem
  padding: 10px 10px 10px 0
  margin: 0 10px 0 4px

.tab-label
  background: transparent
  cursor: pointer
  font-size: 1rem
  font-weight: bold
  &.active
    color: #999
  &.inactive
    color: #999

.nav_tooltip
  visibility: hidden
  background-color: $grey-4
  color: white
  text-align: center
  border-radius: 4px
  font-weight: 600
  font-size: 1rem
  padding: .6rem
  position: absolute
  z-index: 2

.nav_collapsable
  &:hover
    .nav_tooltip
      visibility: visible

.error-block
  height: calc(100% - 60px)
  padding: 10px
  pre
    white-space: pre-wrap
    font-size: 0.9rem
    line-height: 1rem
