//------ properties for Widgets inside Property Panel -----

$property-panel-widget-height: 40px
$property-panel-widget-gap: 0.8em
// label width for form item
$property-panel-field-label-width: 100px

// the left/right padding for the property panel. We use this for some padding
// but also to use as negative margin for the separator line
$widget-property-panel-horizontal-padding: 10px
