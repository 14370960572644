.editable-text
  cursor: pointer
  border: none
  height: 30px
  display: flex
  align-items: center

.editable-text-editing
  cursor: text

