@import "src/styles/_common.sass"

.custom-number-field
  border: none

  .ant-input-number-input
    color: white
    width: 120px
    height: 40px
    padding: 16px
    border: 1px solid $grey-4
    background: $grey-1
    border-radius: 2px
    margin-right: 20px

    &:focus
      border: 1px solid $grey-9
      outline: none

  .ant-input-number-handler
    border: 1px transparent
    border-inline-start: 1px solid $grey-4
    border-start-end-radius: 2px !important
    border-end-end-radius: 2px !important

    &:hover
      border: 1px solid $grey-4


  .ant-input-number-handler-wrap
    background: $grey-1



