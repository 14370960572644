.numeric-stepper
  display: flex
  align-items: center

  input[type="number"]
    width: 120px !important
    margin-right: 10px
    -moz-appearance: textfield

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button
      -webkit-appearance: none
      margin: 0
