.modal

  &-backdrop
    position: fixed
    width: 100%
    height: 100%
    top: 0
    left: 0
    background: rgba(0, 0, 0, 0.8)
    z-index: 500

  &-wrapper
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 700
    width: inherit
    min-width: 300px
    outline: 0

  &-content
    background: black
    border: 8px solid $grey-2
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 32px 40px

    &__header
      padding: 0 12px
      display: flex
      justify-content: space-between
      font-size: 18px
      font-weight: 600

      button
        position: absolute
        top: 16px
        right: 16px
        opacity: .5

        &:hover
          opacity: 1
          
    &__body
      padding: 0 12px

      p
        margin-bottom: 16px
