.theme-editor
  height: calc(100% - 60px)
  width: 90%
  margin-left: -20px

  .button__primary
    position: fixed
    top: 48px
    right: 60px
    padding: 5px 10px
    border-radius: 3px
		
  .update-status
    position: fixed
    top: 60px
    right: 240px
    color: $grey-9
  
  .selected-history-options
    position: fixed
    top: 48px
    right: 300px
    padding: 10px 0
    .close-diff-text
      align-self: center
      padding-right: 20px
      color: $primary
      cursor: pointer
      font-size: 12px
      font-weight: bold
      padding: 10px
      &:hover
        text-decoration: underline

  .dropdown-wrapper
    position: fixed
    top: 48px
    right: 150px
  .dropdown
    border: 1px solid grey
    color: grey
    padding: 7px 10px
    border-radius: 3px
  .dropdown-list
    width: 142px
    left: -1px
  .dropdown-item
    text-decoration: none
  
  .history-dropdown-item
    .item-label
      color: $primary
      font-size: 13px
    .item-time
      color: white
      width: 400px
      font-size: 13px
    .item-name
      font-size: 11px
      color: $grey-9
  
  .save-theme
    position: fixed
    top: 48px
    right: 60px
    .checkin-button
      background-color: $primary
      color: black
      font-weight: bold
      border-radius: 3px 0 0 3px
      padding: 8px 15px
      cursor: pointer
      margin: 0px
    .save-dropdown-wrapper
      margin: -1px
      position: fixed
      top: 48px
      right: 27px
    .save-dropdown
      border: 1px solid black
      background-color: $primary
      border-radius: 0 3px 3px 0
      padding: 5px 0
      height: 100%
      margin: 0px
      padding: 8px 10px 13px 6px
    .save-dropdown-list
      margin: 10px 0 0px -50px
    .save-dropdown-item
      text-decoration: none
      color: $grey-9
      font-size: 12px
      font-weight: bold
  
  .title-bar
    background-color: #141414
    height: 30px
    margin-bottom: 10px
    padding: 5px 0
    .title
      color: $grey-9
      font-size: 14px
      margin-left: 30px
    .current-title
      float: right
      margin-right: 40%