@import "../../styles/common"

.subscription-plans
    display: flex
    gap: 20px
    margin-top: 20px
.subscription-card
    background-color: $grey-2
    padding: 15px
    border-radius: 10px
    width: 270px
    position: relative
    .plan_icon
        height: 30px
        width: 30px
    .title
        margin: 10px 0 20px 0
    .price
        display: flex
        margin: 10px 0
        h6
            font-size: 25px
        span
            margin-top: auto
            padding: 0 10px
            font-size: 13px
            color: $grey-9
    h5
        margin: 20px 0
        font-weight: normal
        font-size: 13px
    .features
        margin: 10px 0 70px 0
        span
            font-size: 12px
            color: $white
            line-height: normal
        .feature
            display: flex
            gap: 10px
            margin-bottom: 10px
            img
                width: 15px
                height: 15px
                margin-bottom: 0
    .action-button
        position: absolute
        bottom: 30px
        width: 88%
        text-align: center
        font-size: 14px
        button
            background-color: $primary
            padding: 10px 30px
            border-radius: 3px
            font-weight: bold
            cursor: pointer