.app-collaborators-content
  padding: 32px 40px 40px 104px

  .app-collaborators-form
    margin-top: 40px
    display: flex
    flex-direction: column
    margin-block: 24px

    input[type='email']
      margin: 8px 0 16px
    input[type='text']
      margin: 8px 0 16px
    input[type='radio']
      margin: 0 8px 0 24px
    input[type='checkbox']
      margin: 0 8px 0 0

    button
      max-width: 150px
      margin-left: 40px

    div
      display: flex
      flex-direction: row
      align-items: center