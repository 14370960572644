// random color generator
$colors: #F44336 #E91E63 #9C27B0 #673AB7 #3F51B5 #2196F3 #03A9F4 #00BCD4 #009688 #4CAF50 #8BC34A #CDDC39 #FFEB3B #FFC107 #FF9800 #FF5722 #795548 #9E9E9E #607D8B

.collaborator-card-ct

  .collaborator-card
    display: flex
    flex-direction: row
    align-items: center
    gap: 10px
    border-bottom: 1px solid $grey-2
    padding: 8px 0
    margin-bottom: 8px
    font-size: 14px

    p:nth-child(2)
      width: 180px
      margin-bottom: 0

    p:nth-child(3)
      width: 240px
      color: $grey-9
      margin-bottom: 0

    .revoke-btn
      color: red
      background: none
      cursor: pointer

    div:last-child,
    p:last-child
      margin-left: auto

    button
      color: white
      font-weight: normal

    a
      text-decoration: none
    
    .collaborator-avatar
      width: 30px
      height: 30px
      border-radius: 50%
      margin-left: -6px
      border: 2px solid black

    .avatar-placeholder
      width: 30px
      height: 30px
      border-radius: 50%
      margin-left: -6px
      border: 2px solid black
      background-color: nth($colors, random(length($colors)))
      display: flex
      justify-content: center
      align-items: center
      
      .avatar-letter
        font-size: 16px
        color: white
        margin: 0
    
.revoke-access-modal
  div
    display: flex
    justify-content: end
    gap: 10px
    margin-top: 20px

  button:first-child
    color: white
    font-size: 16px
    background: none
    cursor: pointer

  button:last-child
    margin-left: 10px
    text-transform: capitalize

.invited-collaborator-card
  display: flex
  flex-direction: row
  align-items: center
  gap: 10px
  border-bottom: 1px solid $grey-2
  padding: 8px 0
  margin-bottom: 8px
  font-size: 14px

  p:nth-child(2)
      width: 180px
      margin-bottom: 0
      font-style: italic

  p:nth-child(3)
      width: 240px
      color: $grey-9
      margin-bottom: 0

  button
    margin-left: auto
    color: $primary
    background: none
    cursor: pointer
