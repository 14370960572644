.editor-content
  position: relative
  overflow: hidden
  display: flex
  flex: 1

  .editor-loading-messages
    position: absolute
    top: 50px
    left: 92px

.editor-ct
  display: flex
  flex: 1
  height: 100vh
  padding-top: 42px
  flex-direction: column
  overflow-x: hidden
  .breadcrumb
    padding: 12px 16px
    background-color: #000
    margin: 0
    position: absolute
    top: 0
    left: 0
    z-index: 999
    width: 100%
    .nav-icon
      width: 1rem
      height: auto
      fill: $grey-9
      &:hover
        fill: $primary-hover
    a
      margin: 0 8px
      &:hover
        color: $white-darker
    .history-section
      display: flex
      margin-left: auto
      padding-right: 50px
      .checkin-button
        background-color: #03ECED
        color: black
        font-weight: bold
        border-radius: 3px 0 0 3px
        padding: 0 15px
        cursor: pointer
        margin: 0px
      .close-diff-text
        align-self: center
        padding-right: 20px
        color: #3dc9b0
        cursor: pointer
        &:hover
          text-decoration: underline

.disable-button
  opacity: 0.5
  cursor: not-allowed !important

  .unsaved-changes
    color: $grey-9
    padding-right: 20px
    font-size: 12px
    display: flex
    .unsaved-changes-icon
      filter: invert(66%) sepia(23%) saturate(3186%) hue-rotate(355deg) brightness(97%) contrast(98%)
      margin-right: 5px
      p
        margin: 0
        padding: 0

      .dropdown
        border: 1px solid grey
        padding: 5px 10px
        border-radius: 3px
      .clone-button
        padding: 7px 10px
        border-radius: 3px
        margin: 0 10px
      .clone-text
        align-self: center
      .save-dropdown-wrapper
        margin: -1px
      .save-dropdown
        border: 1px solid black
        background-color: #03ECED
        border-radius: 0 3px 3px 0
        padding: 5px 0
        height: 100%
        margin: 0px
        padding: 5px 10px 5px 3px
      .save-dropdown-list
        margin: 10px 0 0px -50px

.history-dropdown-item
  .item-label
    color: #3dc9b0
  .item-time
    color: white
    width: 400px
  .item-name
    font-size: 11px

.preview-settings
  position: absolute
  height: 44px
  bottom: 0
  padding: 6px 8px 6px 16px
  background: black
  display: flex
  justify-content: flex-end
  align-items: center
  z-index: 10
  width: 100%

  span, button
    font-size: 11px
    font-weight: 600
    color: $grey-9
    padding: 4px
    margin: 2px

  button
    border-radius: 2px
    &:hover
      color: $primary
      background: black

      svg
        fill: $primary

    svg
      width: 16px
      height: 16px
      fill: $grey-9

.arrow-icon
  margin-left: 4px
  width: 0.6rem
  height: auto
