@import "../../styles/common"

.upload-asset
  border: 1px dashed $grey-9
  display: flex
  width: 35rem
  height: 15rem
  align-items: center
  justify-content: center
  text-align: center
  padding: 3rem 2rem
  flex-direction: column
  gap: 1rem
  cursor: pointer
  &__icon
    width: 40px
    height: 40px
  input
    display: none
  p
    font-size: 18px
    font-weight: 400
