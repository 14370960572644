.color-picker
  height: 40px
  display: flex
  align-items: center

.color-picker-trigger
  display: flex
  align-items: center
  cursor: pointer

  .color-name
    margin-left: 8px
    font-size: 13px
    color: white

// keep original styles
.ant-color-picker-input > span > input[type="text"]
  background-color: initial
  color: initial
  height: initial
  padding: initial
  &:focus,
  &:focus-visible,
  &:active
    border: initial
    outline: initial

.color-box
  width: 24px
  height: 24px
  border-radius: 4px
  border: 4px solid rgba(5, 5, 5, 0.06)
  cursor: pointer
  position: relative
  display: inline-block
  overflow: hidden


.no-color-selected
  @extend .color-box
  background-color: #FFFFFF

.no-color-selected::after
  content: ""
  position: absolute
  inset-inline-end: 1px
  top: 0
  display: block
  width: 40px
  height: 2px
  transform-origin: right
  transform: rotate(-45deg)
  background-color: #f5222d
