.app-preview-modal

  .row
    display: flex
    margin-bottom: 15px
    
    .col
      display: flex
      width: 50%
      padding-right: 10px
      justify-content: center
      align-items: flex-start

      &.para
        flex-direction: column
        gap: 5px
        color: #F5F5F5
        font-size: 14px
        font-style: normal
        font-weight: 600
        line-height: normal

        h3
         margin-bottom: 0
      