.signup-ct
  height: 80vh
  display: flex
  flex-direction: column
  margin: auto
  align-content: center
  align-items: center
  justify-content: center
  text-align: center

  .logo
    width: 200px
    margin: 64px auto 8px

  button
    height: 60px
    width: 260px
    font-size: 16px
    font-weight: 600
    display: flex
    align-items: center
    padding: 0 24px
    margin: 8px auto

    img
      width: 24px
      margin-right: 16px

  .sign-in-error
    margin-top: 24px

  .tc
    font-size: 13px
    width: 360px
    color: $grey-9
    a
      color: $grey-9
      text-decoration: underline

.sign-in
  width: 400px
  margin-top: 16px
  border-top: 2px solid $grey-2

  p
    margin: 16px 0
