.debug-panel
  position: absolute
  bottom: 0
  left: 0
  right: 0
  background: #111
  z-index: 11
  border-top: 10px solid #000

  &.close
    height: 49px

  .debug-header
    padding: 10px
    display: flex
    flex-direction: row
    align-items: center
    cursor: pointer
    height: 40px
    border-bottom: 1px solid #222
    font-size: 14px
    font-weight: 500

    .icon-container
      margin-left: 10px
      display: flex
      flex-direction: row
      align-items: center
      gap: 15px

    div
      display: flex
      align-items: center
      gap: 5px

    .show-icon
      transform: rotate(270deg)

    .clear_btn
      height: 28px
      padding: 0px 12px 0px 12px
      border-radius: 4px
      background-color: #202020
      color: #999
      font-size: 14px
      font-weight: 600
      z-index: 10
      margin-left: 10px
      cursor: pointer

  .debug-content
    height: 100%
    height: 300px
    overflow-y: scroll
    padding: 5px

    .debug-warn
      background: #413C26

    .debug-error
      background: #4E3534

    .debug-info
      background: #3D4A5E

    &__content
      display: flex
      justify-content: space-between
      font-family: monospace
      font-size: 14px
      line-height: 20px
      padding: 10px
      padding-left: 3px
      margin-bottom: 5px
      border-radius: 5px

      &__with-icon
        display: flex
        gap: 10px

        .icon
          width: 30px
          svg
            width: 30px

        pre
          white-space: pre-wrap

      &__line
        width: 400px
        text-align: right
        display: block

        &__no
          color: #66A9FA
          text-decoration: underline
          text-underline-offset: 2px
